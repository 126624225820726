import React from 'react';

const LiderAbsolutaSvg: React.FC = () => {
    return (
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 411.02 532.91">
  <defs>
    <style>{`
      .cls-1 {
        font-family: FuturaPT-Heavy, 'Futura PT';
        font-size: 104.18px;
        font-weight: 800;
        letter-spacing: -.04em;
      }

      .cls-1, .cls-2 {
        fill: #ededed;
      }

      .cls-3 {
        fill: #ff1430;
      }

      .cls-4 {
        fill: #18131f;
      }
    `}</style>
  </defs>
  <rect className="cls-3" x="0" y="-.17" width="411.02" height="164.92"/>
  <g>
    <path className="cls-2" d="M128.3,31.84h-30.22v101.77h13.79v-45.97h15.2c1.35,8.4,1.48,43.25,1.48,45.97h13.97c.43-25.83.18-73.96-14.22-101.77M111.68,75.83v-32.38h7.39c2.71,5.68,4.49,13.97,5.6,20.08,1.17,6.67,1.79,12.3,1.79,12.3h-14.77Z"/>
    <rect className="cls-2" x="71.99" y="31.84" width="15.14" height="101.83"/>
    <polygon className="cls-2" points="33.6 31.84 33.6 133.67 48.75 133.67 48.75 87.57 59.83 87.57 59.83 75.77 48.75 75.77 48.75 43.58 62.97 43.58 62.97 31.84 33.6 31.84"/>
    <polygon className="cls-2" points="142.62 31.84 142.62 43.58 153.45 43.58 153.51 133.61 168.44 133.61 168.44 43.58 179.37 43.58 179.37 31.84 142.62 31.84"/>
  </g>
  <g>
    <polygon className="cls-2" points="358.87 -.17 336.86 67.67 347.05 67.67 369.06 -.17 358.87 -.17"/>
    <polygon className="cls-2" points="338.49 -.17 316.48 67.67 326.67 67.67 348.68 -.17 338.49 -.17"/>
    <polygon className="cls-2" points="379.25 -.17 357.24 67.67 367.43 67.67 389.44 -.17 379.25 -.17"/>
    <polygon className="cls-2" points="399.63 -.17 377.62 67.67 387.81 67.67 409.82 -.17 399.63 -.17"/>
  </g>
  <path className="cls-2" d="M346.85,421.73h30v49.44h-30v51.09h-59.99v-51.09h-119.98v-41.53l94.93-155.91h85.04v148ZM286.86,421.73v-97.9l-57.02,97.9h57.02Z"/>
  <text className="cls-1" transform="translate(26.29 266.12)"><tspan x="0" y="0">LÍDER</tspan></text>
  <rect className="cls-2" y="164.23" width="411.02" height="368.68"/>
  <path className="cls-4" d="M346.85,421.73h30v49.44h-30v51.09h-59.99v-51.09h-119.98v-41.53l94.93-155.91h85.04v148ZM286.86,421.73v-97.9l-57.02,97.9h57.02Z"/>
  <g>
    <path className="cls-4" d="M40.12,322.72h11.19v-10.15h3.91v25.94h-3.91v-12.11h-11.19v12.11h-3.91v-25.94h3.91v10.15Z"/>
    <path className="cls-4" d="M76.67,332.21h-11.1l-2.89,6.3h-4.22l12.83-27.57,12.38,27.57h-4.28l-2.72-6.3ZM75.08,328.54l-3.85-8.82-4.03,8.82h7.88ZM77.25,305.61l-6.85,4.64-1.86-1.56,5.39-5.41,3.33,2.33Z"/>
  </g>
  <g>
    <path className="cls-4" d="M52.44,295.21h-11.1l-2.89,6.3h-4.22l12.83-27.57,12.38,27.57h-4.28l-2.72-6.3ZM50.85,291.54l-3.85-8.82-4.03,8.82h7.88Z"/>
    <path className="cls-4" d="M62.69,301.51v-25.94h3.9c1.74,0,3.12.13,4.15.4,1.04.27,1.92.72,2.64,1.36.73.66,1.3,1.48,1.73,2.47.44,1,.66,2,.66,3.02,0,1.86-.71,3.43-2.13,4.72,1.38.47,2.46,1.29,3.26,2.47.81,1.16,1.21,2.52,1.21,4.06,0,2.03-.72,3.74-2.15,5.14-.86.86-1.83,1.46-2.91,1.8-1.18.33-2.65.49-4.42.49h-5.93ZM66.61,286.71h1.23c1.46,0,2.52-.32,3.2-.97.68-.64,1.02-1.59,1.02-2.85s-.35-2.15-1.04-2.78-1.7-.95-3.02-.95h-1.38v7.54ZM66.61,297.83h2.42c1.77,0,3.07-.35,3.9-1.04.87-.75,1.31-1.7,1.31-2.86s-.42-2.06-1.26-2.82c-.82-.75-2.28-1.13-4.38-1.13h-1.98v7.84Z"/>
    <path className="cls-4" d="M97.7,279.44l-3.17,1.88c-.59-1.03-1.16-1.7-1.7-2.02-.56-.36-1.28-.54-2.17-.54-1.09,0-1.99.31-2.7.92-.72.6-1.08,1.37-1.08,2.28,0,1.27.94,2.29,2.82,3.06l2.59,1.06c2.11.85,3.65,1.89,4.62,3.12.97,1.23,1.46,2.73,1.46,4.51,0,2.38-.79,4.36-2.38,5.91-1.6,1.57-3.59,2.35-5.96,2.35-2.25,0-4.11-.67-5.58-2-1.45-1.33-2.35-3.21-2.71-5.63l3.96-.87c.18,1.52.49,2.58.94,3.16.81,1.12,1.98,1.68,3.53,1.68,1.22,0,2.23-.41,3.04-1.23.81-.82,1.21-1.85,1.21-3.11,0-.5-.07-.97-.21-1.39-.14-.42-.36-.81-.66-1.16s-.68-.68-1.15-.99c-.47-.31-1.03-.6-1.68-.88l-2.5-1.04c-3.55-1.5-5.33-3.7-5.33-6.58,0-1.95.75-3.58,2.23-4.89,1.49-1.32,3.34-1.98,5.56-1.98,2.99,0,5.33,1.46,7,4.37Z"/>
    <path className="cls-4" d="M101.8,288.43c0-3.65,1.34-6.79,4.01-9.41,2.67-2.62,5.87-3.93,9.61-3.93s6.86,1.32,9.51,3.96c2.65,2.64,3.98,5.82,3.98,9.52s-1.33,6.89-4,9.49c-2.68,2.61-5.91,3.91-9.69,3.91-3.35,0-6.36-1.16-9.02-3.48-2.93-2.56-4.4-5.92-4.4-10.08ZM105.74,288.48c0,2.87.96,5.22,2.89,7.07,1.91,1.85,4.13,2.77,6.63,2.77,2.72,0,5.02-.94,6.89-2.82,1.87-1.9,2.81-4.22,2.81-6.95s-.92-5.08-2.77-6.95c-1.84-1.88-4.11-2.82-6.82-2.82s-4.98.94-6.84,2.82c-1.86,1.86-2.79,4.15-2.79,6.89Z"/>
    <path className="cls-4" d="M137.74,275.58v22.26h7.63v3.68h-11.54v-25.94h3.91Z"/>
    <path className="cls-4" d="M152.2,275.58v15.62c0,2.23.36,3.86,1.09,4.89,1.09,1.49,2.62,2.23,4.59,2.23s3.52-.75,4.6-2.23c.73-1,1.09-2.63,1.09-4.89v-15.62h3.91v16.7c0,2.73-.85,4.98-2.55,6.75-1.92,1.97-4.27,2.96-7.05,2.96s-5.13-.99-7.04-2.96c-1.7-1.77-2.55-4.02-2.55-6.75v-16.7h3.91Z"/>
    <path className="cls-4" d="M180.86,279.26v22.26h-3.91v-22.26h-5.96v-3.68h15.82v3.68h-5.95Z"/>
    <path className="cls-4" d="M204.13,295.21h-11.1l-2.89,6.3h-4.22l12.83-27.57,12.38,27.57h-4.28l-2.72-6.3ZM202.53,291.54l-3.85-8.82-4.03,8.82h7.88Z"/>
  </g>
  <g>
    <path className="cls-4" d="M49.94,196.84v55.95h21.57v13.54h-37.61v-69.49h16.04Z"/>
    <path className="cls-4" d="M100.47,177.46l-17.09,13.44-6.77-5.52,13.96-15.42,9.9,7.5ZM92.76,196.84v69.49h-16.04v-69.49h16.04Z"/>
    <path className="cls-4" d="M126.1,196.84c9.17,0,18.86,1.35,27.4,9.59,6.15,5.94,10,14.06,10,24.9s-3.75,18.86-9.48,24.59c-9.58,9.69-21.57,10.42-27.4,10.42h-22.5v-69.49h21.98ZM120.16,252.79h4.27c3.33,0,10.63-.31,15.84-4.69,3.75-3.13,6.77-9.06,6.77-16.77s-3.02-12.29-5.73-15c-5.21-5.21-11.98-5.94-16.88-5.94h-4.27v42.4Z"/>
    <path className="cls-4" d="M210.59,210.38h-23.23v13.44h22.09v13.54h-22.09v15.42h23.23v13.54h-39.28v-69.49h39.28v13.54Z"/>
    <path className="cls-4" d="M243.82,196.84c8.86,0,14.38,2.92,17.71,6.04,2.92,2.81,6.04,7.81,6.04,15.52,0,4.38-.94,9.79-5.42,14.17-2.4,2.29-5.83,4.38-9.69,5.31l21.36,28.44h-19.59l-18.75-27.4v27.4h-16.04v-69.49h24.38ZM235.49,228.41h3.65c2.92,0,6.88-.31,9.58-3.02,1.15-1.15,2.6-3.33,2.6-6.77,0-3.96-1.88-6.04-3.23-7.08-2.6-1.98-6.77-2.19-8.86-2.19h-3.75v19.07Z"/>
  </g>
  <g>
    <path className="cls-4" d="M359.04,341.3v-8.32l-4.16-1.68v-5.28l22.01,8.88v4.65l-22.01,8.68v-5.28l4.16-1.65ZM362.94,339.91l7.39-2.71-7.39-2.74v5.45Z"/>
    <path className="cls-4" d="M354.88,349.38h22.01v4.42l-14.19,11.95h14.19v5.08h-22.01v-4.42l14.26-11.95h-14.26v-5.08Z"/>
    <path className="cls-4" d="M374.19,393.85c-2.01,2.08-4.92,3.43-8.32,3.43-2.94,0-5.94-1.06-8.25-3.43-1.98-2.05-3.23-4.82-3.23-8.51,0-4.13,1.65-6.93,3.23-8.58,1.98-2.11,4.95-3.43,8.19-3.43s6.37,1.42,8.35,3.4c1.49,1.48,3.23,4.22,3.23,8.61,0,3.43-1.06,6.3-3.2,8.51ZM370.83,380.48c-.92-.96-2.54-1.95-4.98-1.95-2.01,0-3.63.66-4.95,2.01-1.35,1.42-1.82,3-1.82,4.72,0,2.24.83,3.83,1.88,4.88.83.86,2.38,1.91,4.92,1.91,2.28,0,3.96-.92,4.95-1.91,1.06-1.09,1.88-2.84,1.88-4.85s-.69-3.63-1.88-4.82Z"/>
    <path className="cls-4" d="M371.52,411.09c1.48-1.65,1.58-3.07,1.58-3.7,0-.69-.1-1.55-.76-2.15-.33-.33-.83-.56-1.39-.56-.53,0-.92.17-1.22.5-.5.53-.69,1.29-1.25,2.77l-.63,1.65c-.36.96-.86,2.15-1.68,3-1.22,1.29-2.81,1.62-4.09,1.62-2.28,0-4.26-.79-5.45-1.95-2.05-1.95-2.24-4.79-2.24-6.24,0-1.58.23-2.97.92-4.36.56-1.12,1.58-2.41,2.41-3.23l3.63,2.64c-.56.56-1.32,1.45-1.68,2.05-.53.86-.79,1.75-.79,2.77,0,.66.13,1.58.76,2.31.36.43.96.79,1.78.79.73,0,1.19-.3,1.58-.76.5-.59.99-1.95,1.22-2.57l.63-1.82c.36-1.02.83-2.21,1.75-3.07,1.22-1.16,2.77-1.32,3.83-1.32,1.95,0,3.6.59,4.95,1.91,1.58,1.55,2.01,3.4,2.01,5.45,0,1.52-.26,3.96-2.18,6.54l-3.7-2.28Z"/>
  </g>
</svg>);
}

export default LiderAbsolutaSvg;